import { Box, Heading, Stack, Text as ChakraText } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { UnknownObjectAny } from 'types/global.types';
import { FeatureSplit, ListGenerator } from '.';
import { CONTENT_TYPES } from 'utils/constants';
import { presetComponentProps } from 'theme';
import Text, { TextProps } from './text/Text';
import ImageBanner, { ImageBannerProps } from './banners/ImageBanner';
import ImageBannerTextOverlay, {
  ImageBannerTextOverlayProps
} from './banners/ImageBannerTextOverlay';
import { ListCMSData } from 'types/cms/fragments';
import HomeHeroBanner, { HomeHeroBannerProps } from './banners/HomeHeroBanner';
import { ButtonGroup, ButtonGroupProps } from './ButtonGroup';

const headlineRegex =
  /^(How to get started on your caregiver support journey|Locations|Asymptomatic COVID-19 Testing Clinic Locations)$/i;
const pathRegex =
  /^(\/health-clinics\/fertility-health|\/pharmacy-services\/teva-caregiver-support-program|\/covid-19-information\/alberta|\/covid-19-information\/british-columbia)(#book|$)$/;

export interface ComponentGeneratorProps extends UnknownObjectAny {
  __typename: string;
}

const ComponentGenerator = ({
  __typename,
  listProps = {},
  ...props
}: ComponentGeneratorProps): JSX.Element | null => {
  const { asPath } = useRouter();
  // https://64labs.atlassian.net/browse/LDP-137 & https://64labs.atlassian.net/browse/LDP-143: Need to insert an id for deep linking to the Locations section of various pages
  const shouldHaveBookHash =
    props?.headline && headlineRegex.test(props.headline) && pathRegex.test(asPath);
  const nestedListItemTypeName = props?.itemCollection?.items?.[0]?.__typename;

  switch (__typename) {
    case CONTENT_TYPES.LIST:
      return (
        <Box w="100%" mb={{ base: '10', md: '16' }} {...listProps}>
          {nestedListItemTypeName !== CONTENT_TYPES.FAQ_ITEM && (props?.headline || props?.text) && (
            <Stack
              alignItems="center"
              mb={{ base: '1rem', md: '2rem' }}
              spacing={{ base: '1', md: 0 }}
            >
              {props?.headline && (
                <Heading
                  {...presetComponentProps?.h2}
                  color={props?.headlineColor || 'primary.default'}
                  id={shouldHaveBookHash ? 'book' : ''}
                >
                  {props?.headline}
                </Heading>
              )}
              {props?.text && <ChakraText>{props.text}</ChakraText>}
            </Stack>
          )}
          <ListGenerator {...(props as ListCMSData)} />
        </Box>
      );

    case CONTENT_TYPES.FEATURE_SPLIT:
      return (
        <Box w="100%" mb={{ base: '10', md: '16' }}>
          <FeatureSplit
            {...props}
            title={props.title}
            image={props.image}
            imageLocation={props.imageLocation}
          />
        </Box>
      );
    case CONTENT_TYPES.TEXT:
      return (
        <Box w="100%" mb={{ base: '10', md: '16' }}>
          <Text
            data={props as TextProps['data']}
            overrideTextAlign={props?.overrideTextAlign}
            overrideTextWidth={props?.overrideTextWidth}
          />
        </Box>
      );
    case CONTENT_TYPES.IMAGE_BANNER:
      return (
        <Box w="100%" mb={{ base: '10', md: '16' }}>
          <ImageBanner {...(props as ImageBannerProps)} />
        </Box>
      );
    case CONTENT_TYPES.IMAGE_BANNER_WITH_TEXT_OVERLAY:
      return (
        <Box w="100%" mb={{ base: '10', md: '16' }}>
          <ImageBannerTextOverlay {...(props as ImageBannerTextOverlayProps)} />
        </Box>
      );
    case CONTENT_TYPES.HOME_HERO_BANNER:
      return (
        <Box w="100%" mb={{ base: '10', md: '16' }}>
          <HomeHeroBanner {...(props as HomeHeroBannerProps)} />
        </Box>
      );
    case CONTENT_TYPES.BUTTON_GROUP_LIST:
      return (
        <Box w="100%" mb={{ base: '10', md: '16' }}>
          <ButtonGroup {...(props as ButtonGroupProps)} />
        </Box>
      );
    case CONTENT_TYPES.D365_FORM:
      // const {orgId, formId, isFormValid} = props;
      if (props?.isFormValid) {
        const orgId = props?.orgId ?? process.env.CONTENTFUL_ORG_ID;
        return (
          <div
            data-form-id={props?.formId}
            data-form-api-url={`https://public-can.mkt.dynamics.com/api/v1.0/orgs/${orgId}/landingpageforms`}
            data-cached-form-url={`https://assets-can.mkt.dynamics.com/${orgId}/digitalassets/forms/${props?.formId}`}
          />
        );
      } else return <></>;
    default:
      return null;
  }
};

export default ComponentGenerator;
